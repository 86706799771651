import React from 'react';

import './index.scss';
import Header from '../../components/Header';
import SupportedBy from '../../components/supportedby/index';
import {SectionHeader} from '../../components/SectionHeader/index';
import {Community} from '../../components/Community/index';
import {Footer} from '../../components/Footer/index';

const Single = () => {
  return (
    <>
      <Header>
        <section className="jumbotron inner__container">
          <section className="left__section">
            <button className="btn proman">izyProMan</button>
            <p className="main__text">Discover best properties in one place</p>
            <div className="search-sort">
              <div className="fields">
                <select>
                  <option>select state</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select LGA</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select Category</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select min amount</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select max amout</option>
                </select>
              </div>
              <div className="fields submits">
                <button className="search-btn">Search</button>
              </div>
            </div>
          </section>
        </section>
      </Header>

      <section className="supported__section">
        <section className="body__container">
          <SupportedBy />

          <section>
            <div className="image-display">
              <div>hello</div>
              <div>
                <div>image</div>
                <div>image</div>
                <div>image</div>
                <div>image</div>
              </div>
            </div>

            <div>
              <div>
                <p>
                  <strong>Property Details</strong>
                </p>
                <p>
                  We reduce the cost of living of our users by as much as 30%.
                  We reduce cost of living and help you meet your most important
                  living expectations by increasing izy Access to all the
                  necessary resources needed to improve living standards. We
                  reduce the cost of living of our users by as much as 30%. We
                  reduce cost of living and help you meet your most important
                  living expectations by increasing izy Access to all the
                  necessary resources needed to improve living standards. We
                  reduce the cost of living of our users by as much as 30%. We
                  reduce cost of living and help you meet your most important
                  living expectations by increasing izy Access to all the
                  necessary resources needed to improve living standards.
                </p>
                <p>
                  We reduce the cost of living of our users by as much as 30%.
                  We reduce cost of living and help you meet your most important
                  living expectations by increasing izy Access to all the
                  necessary resources needed to improve living standards.
                </p>
              </div>
              <div>
                <p>
                  <strong>Amenities</strong>
                </p>
                <div className="group">
                  <div>
                    <ul>
                      <li>Room: 2</li>
                      <li>Meter: prepaid</li>
                      <li>Floor: Ground floor</li>
                      <li>Floor: Ground floor</li>
                      <li>Entrances: 2</li>
                      <li>Occupancy: Vacant</li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>Parking space: No</li>
                      <li>Verander: Yes</li>
                      <li>Finishing: tiles</li>
                      <li></li>
                      <li></li>
                      <li>
                        <button>Schedule Tour</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <h2 className="similar">Similar Listing</h2>
          <section className="what__section">
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
          </section>

          <section className="steps">
            <SectionHeader centered>Steps To Join</SectionHeader>

            <div className="steps__container">
              <div className="step__one">
                <div className="step">01</div>
                <button className="btn steps__btn">Register</button>
                <p className="text">Complete the registration form</p>
              </div>
              <div className="step__two">
                <div className="step">02</div>
                <button className="btn steps__btn">Verify Email</button>
                <p className="text">
                  Verify your email with the link sent to your mail box.
                </p>
              </div>
            </div>
          </section>
        </section>
      </section>

      <Community />

      <section className="questions__section">
        <section className="body__container">
          <section className="questions__grid">
            <div className="questions__left">
              <p>Got questions?</p>
            </div>
            <div className="questions__right">
              <p>
                Whatever question you have, we have an answer for it! Reach out
                to our help center to know more about{' '}
                <span style={{color: '#C8222C'}}>Liveizy.</span>
              </p>
            </div>
          </section>
        </section>
      </section>

      <Footer />
    </>
  );
};

export default Single;
