import React from 'react';

import './index.scss';
import Header from '../../components/Header';
import SupportedBy from '../../components/supportedby/index';
import {SectionHeader} from '../../components/SectionHeader/index';
import {Community} from '../../components/Community/index';
import {Footer} from '../../components/Footer/index';

const Listing = () => {
  return (
    <>
      <Header>
        <section className="jumbotron inner__container">
          <section className="left__section">
            <button className="btn proman">izyProMan</button>
            <p className="main__text">Discover best properties in one place</p>
            <div className="search-sort">
              <div className="fields">
                <select>
                  <option>select state</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select LGA</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select Category</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select min amount</option>
                </select>
              </div>
              <div className="fields">
                <select>
                  <option>select max amout</option>
                </select>
              </div>
              <div className="fields submits">
                <button className="search-btn">Search</button>
              </div>
            </div>
          </section>
        </section>
      </Header>

      <section className="supported__section">
        <section className="body__container">
          <SupportedBy />

          <section className="what__section">
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
            <div className="product">
              <div className="image">
                <img src="" alt="" />
              </div>
              <div className="details">
                <p className="title">
                  <strong>Chevron Drive Estate</strong>
                </p>
                <p>
                  <span>icon</span>123 Chevron Drive, New york NIgeria
                </p>
                <p>3 Bedroom, 2baths</p>
                <p className="price">N20,000,000</p>
              </div>
            </div>
          </section>

          <section className="steps">
            <SectionHeader centered>Steps To Join</SectionHeader>

            <div className="steps__container">
              <div className="step__one">
                <div className="step">01</div>
                <button className="btn steps__btn">Register</button>
                <p className="text">Complete the registration form</p>
              </div>
              <div className="step__two">
                <div className="step">02</div>
                <button className="btn steps__btn">Verify Email</button>
                <p className="text">
                  Verify your email with the link sent to your mail box.
                </p>
              </div>
            </div>
          </section>
        </section>
      </section>

      <Community />

      <section className="questions__section">
        <section className="body__container">
          <section className="questions__grid">
            <div className="questions__left">
              <p>Got questions?</p>
            </div>
            <div className="questions__right">
              <p>
                Whatever question you have, we have an answer for it! Reach out
                to our help center to know more about{' '}
                <span style={{color: '#C8222C'}}>Liveizy.</span>
              </p>
            </div>
          </section>
        </section>
      </section>

      <Footer />
    </>
  );
};

export default Listing;
