import React from 'react';

import paystack from '../../static/images/stack.png';
import commsworth from '../../static/images/commsworth.png';
import ryde from '../../static/images/ryde.png';
import photizzo from '../../static/images/phozzito.svg';
import gaps from '../../static/images/gaps.png';

import './index.scss';
const SupportedBy = () => {
  return (
    <div className="support__title">
      <h2>Supported by</h2>

      <div className="company">
        <div className="company__logo">
          <img src={paystack} alt="" />
        </div>
        <div className="company__logo">
          <img src={gaps} alt="" />
        </div>
        <div className="company__logo ">
          <img src={commsworth} alt="" />
        </div>
        <div className="company__logo">
          <img src={photizzo} alt="" />
        </div>
        <div className="company__logo">
          <img src={ryde} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SupportedBy;
