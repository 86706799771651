import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Loading from './components/loading/Loading';
import Listing from './pages/Listing/index';
import Single from './pages/Listing/Single';

const LandingPage = lazy(() => import('./pages/landing-page'));
const IzyProman = lazy(() => import('./pages/izyproman'));
const IzyRent = lazy(() => import('./pages/izyrent'));
const IzyPay = lazy(() => import('./pages/izyPay'));
const IzyFood = lazy(() => import('./pages/izyFoods'));
const IzyAbout = lazy(() => import('./pages/izyAbout'));

const App: React.FC = () => (
  <Router>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route path="/izyproman">
          <IzyProman />
        </Route>
        <Route path="/izyrent">
          <IzyRent />
        </Route>
        <Route path="/izypay">
          <IzyPay />
        </Route>
        <Route path="/izyfood">
          <IzyFood />
        </Route>
        <Route path="/izyabout">
          <IzyAbout />
        </Route>
        <Route path="/izylisting">
          <Listing />
        </Route>
        <Route path="/single">
          <Single />
        </Route>
        <Redirect from="/register" to="/"/>
        <Redirect from="/login" to="/"/>
        <Redirect from="/services" to="/"/>
        <Redirect from="izysaver" to="/"/>
        <Redirect from="/rent" to="/izyrent"/>
        <Redirect from="/tenant" to="/"/>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
