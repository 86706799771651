import  { useState, useEffect } from "react";

const useUrl = () => {
    const [login, setAppRouteLogin] = useState("")
    const [register, setAppRouteRegister] = useState("")
  
    useEffect(() => {
      const routelogin:any = process.env.REACT_APP_WEBAPP_URL
      const routeRegister:any = process.env.REACT_APP_WEBAPP_SIGNUP_URL
  
      setAppRouteRegister(routeRegister)
      setAppRouteLogin(routelogin)
    
    })
    return { login, register};
};
 
export default useUrl ;