import React, {useState, useLayoutEffect} from 'react';

import {createCtx} from '../CreateCtx';

type LayoutType = {
  isMobile: boolean;
};

const [useLayoutContext, CtxProvider] = createCtx<LayoutType>();

function LayoutProvider(props: React.PropsWithChildren<unknown>) {
  const layoutData = useProvideLayout();

  return <CtxProvider value={layoutData}>{props.children}</CtxProvider>;
}

function useProvideLayout(): LayoutType {
  const [isMobile, setIsMobile] = useState(false);

  const handleLayout = () => {
    const breakpoint = '(max-width: 1112px)';

    const matchList = window.matchMedia(breakpoint);

    if (matchList.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return matchList;
  };

  useLayoutEffect(() => {
    const matchListener = handleLayout();

    if (window) {
      matchListener.addListener(handleLayout);
    }

    return () => matchListener.removeListener(handleLayout);
  }, []);

  return {isMobile};
}

export {LayoutProvider, useLayoutContext};
