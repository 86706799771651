import React, {Fragment, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {BsChevronDown} from 'react-icons/bs';
import {useLayoutContext} from '../context/layout';
import liveizyLogo from '../static/images/liveizy-logo-inverse (1).png';
import '../pages/landing-page/styles.css';
import useUrl from './customHook/useUrl';


type Props = any;

const Header: React.FunctionComponent<Props> = (props: Props) => {
  const [hamOpen, setHamOpen] = useState(false);
  const {isMobile} = useLayoutContext();
  const {login, register} = useUrl();
  


  const handleHamburger = () => {
    setHamOpen(prevState => !prevState);
    // handleSubMenu();
  };

  const handleNavLink = () => {
    setHamOpen(false);

    let menu = document.getElementById('menu-div');
    menu?.classList.toggle('show-menu');
  };

  const handleSubMenu = () => {
    if (isMobile) {
      let menu = document.getElementById('menu-div');
      menu?.classList.toggle('menu');
    } else {
      let menu = document.getElementById('menu-div');
      menu?.classList.toggle('show-menu');
    }
  };

  const handleScrollTop = () => {
    if (window.scrollY > 700) {
      console.log('above');
    } else if (window.scrollY < 70) {
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollTop);
    return () => {
      window.removeEventListener('scroll', handleScrollTop);
    };
  });

  return (
    <div
      className={hamOpen ? 'nav-open' : ''}
      style={{backgroundColor: '#030636'}}
    >
      <div className="container-fluid">
        <header className="inner__container">
          <div className="logo">
            <Link to="/">
              <img src={liveizyLogo} alt="" />
            </Link>
          </div>

          {isMobile && (
            <div>
              <Link className="nav-toggle" to="#" onClick={handleHamburger}>
                <span className="hamburger"></span>
              </Link>
            </div>
          )}

          <nav className={isMobile ? 'nav' : 'navigate'}>
            <ul className="nav__list">
              <li className="nav__item">
                <Link
                  to="/izyabout"
                  className="nav__link"
                
                >
                  About us
                </Link>
              </li>
              <li className="nav__item">
                <Link to="#" className="nav__link" onClick={handleSubMenu}>
                  All Service <BsChevronDown style={{paddingTop: '0.25em'}} />
                </Link>
                <div className="menu " id="menu-div">
                  <ul>
                    <li>
                      <Link to="/izyrent" onClick={handleNavLink}>
                        izyRent
                      </Link>
                    </li>
                    <li>
                      <Link to="/izyproman" onClick={handleNavLink}>
                        izyProman
                      </Link>
                    </li>
                    <li>
                      <Link to="/izypay" onClick={handleNavLink}>
                        izyPay
                      </Link>
                    </li>
                    <li>
                      <Link to="/izyfood" onClick={handleNavLink}>
                        izyFood
                      </Link>
                    </li>
                    {/* {isMobile && (
                      <li>
                        <Link to="#" onClick={handleNavLink} className="login">
                          Login
                        </Link>
                      </li>
                    )} */}
                  </ul>
                </div>
              </li>
              <li className="nav__item">
                <Link to="" className="nav__link">
                  Find a Home
                </Link>
              </li>{' '}
              {isMobile && (
                <div className="auth__item" style={{display:"flex"}}>
                <li className="nav__item">
                  <a
                    className="login_nav_btn nav_btn"
                    href={login}
                  >
                    Login
                  </a>
                </li>
                <li className="nav__item">
              <a className="login_nav_btn nav_btn" href={register}>
                Sign up
              </a>
                </li>
                </div>
              )}
              
            </ul>
          </nav>

          {!isMobile && (
            <div className="nav_buttons">
              <a className="login_nav_btn nav_btn" href={login}>
                Login
              </a>
              <a className="signup_nav_btn nav_btn" href={register}>
                Sign up
              </a>
            </div>
          )}
        </header>
      </div>

      <div className="inner__container container ">{props.children}</div>
    </div>
  );
};

export default Header;
