import React from 'react';

import Root from './Root';
import ErrorBoundary from './ErrorBoundary';
import {LayoutProvider} from './context/layout';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <div className="App">
        <LayoutProvider>
          <Root />
        </LayoutProvider>
      </div>
    </ErrorBoundary>
  );
};

export default App;
