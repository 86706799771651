import React from 'react';
import classNames from 'classnames';

import {useLayoutContext} from '../../context/layout';

import work from '../../static/images/work.svg';
import community from '../../static/images/community.jpg';
import './community.css';

export function Community() {
  const {isMobile} = useLayoutContext();

  return (
    <section className="community__section" style={{paddingTop: '0.25em'}}>
      <section className="body__contains">
        <section className="community__grid">
          <div className="community__left">
            <div className="box">
              <img style={{width: '2em'}} src={work} alt="" />
            </div>
            <h3 className="heading">Join Our Community</h3>
            <p className="text">
              We reduce cost of living and help you meet your most important
              living expectations by increasing izy access to all the necessary
              resources needed to improve living standards.
            </p>
          </div>
          <div className="community__right">
            <div className="community__image">
              <img src={community} alt="" />
            </div>
          </div>
        </section>
      </section>
      <div className="community__living">
        <div
          className={classNames('community__living community__box', {
            'community__box--desktop': !isMobile,
          })}
        >
          We reduce the cost of living of our users by as much as 30%.
        </div>
      </div>
    </section>
  );
}
