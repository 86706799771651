import React from 'react';

import './sectionheader.css';

interface Props extends React.PropsWithChildren<unknown> {
  centered?: boolean;
  short?:boolean;
}

export function SectionHeader(props: Props) {
  return (
    <div
      className={`section__header ${
        props.centered ? 'section__header--centered' : ''
      }`}
    >
      <p className={`header__text ${props.short ? 'mx-width':" "}`}>{props.children}</p>
      <div className="footnote"></div>
    </div>
  );
}
